import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  PhoneIcon,
  EyeDropperIcon,
  ArrowsPointingOutIcon,
  CubeIcon,
  BeakerIcon,
  FunnelIcon,
} from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const AboutPage = ({ active = `about` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | About Us" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-mygreen"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        Our Methodological Approach
      </p>
      <StaticImage
        src="../images/image-method.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8">
        <h3 className="text-mygreen text-2xl md:text-3xl mb-8">Our Methods</h3>
        <h2 className="text-4xl text-gray-700 md:text-5xl mb-12">
          Five Process Approach for measuring Employee Well-Being
        </h2>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          We use academic and well researched methods to evaluate and measure
          employee well-being at work.
        </p>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          The Strategic approach to assessing well-being in organizations
          prevents pitfalls and mistakes that ignore process. Some organizations
          hold the view that off-the-shelf programs (e.g. web portal) will work
          by itself. They fail to take the time to understand staff needs and
          interests and the best delivery methods for engaging staff.
        </p>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          Other organizations believe some new wearable technology, pretty
          website, or other technological asset, or a nifty incentive system
          results in better engagement. All that glitters is not gold.
        </p>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          Employers assume that a wellness strategy can be effective without
          partnerships and collaborations. A process perspective approach to
          measuring well-being helps you design and deliver programs while
          partnering with other functions, such as safety, training, talent
          development, risk management, leadership and customer service.
        </p>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          Return of Investments (ROI), without a process viewpoint, businesses
          can get overly focused on achieving some financial return. These
          returns are significantly more likely with careful attention to
          context – the culture, details of the local needs, and optimal design
          and delivery requirements. Many other outcomes come before and mediate
          financial returns (e.g. participation rates, employee engagement,
          health and well-being employees, leadership support). The evaluation
          systems should capture this process information.
        </p>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto mb-12 text-center">
        <StaticImage
          src="../images/process-model.png"
          alt="Five Process approach model - Assess, Design, Deliver, Implement, Evaluate"
          className="h-full"
        />
      </div>
    </section>

    <section className="py-16 max-w-[1400px] mx-auto bg-slate-50">
      <h3 className="text-myorange text-5xl mb-4 text-center">
        <EyeDropperIcon className="inline h-8 w-8" /> Assess
      </h3>
      <h2 className="text-4xl md:text-2xl mb-6 text-center">
        Readiness, Needs, and Climate
      </h2>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg">
        Assessing the most important Assess priorities that will link Employee
        Well-Being and Productivity. We specialize in measuring employee
        well-being. We assess the staff, systems, programs and the environment
        of the workplace continuously. We do this using assessment which staff
        complete anonymously.
      </p>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg pt-8">
        Organizations use the results from these assessments to build a
        strategic health and well-being programme which is more likely to be
        effective because it is based on the actual needs of those for whom it
        is intended. The results will be presented using a variety of graphics
        and charts. Our assessments cover all aspects of employee well-being.
        Our assessment of employee well-being will be validated using an
        established, scientific approach known as Impact Analysis
      </p>
    </section>

    <section className="py-16 max-w-[1400px] mx-auto">
      <h3 className="text-myorange text-5xl mb-4 text-center">
        <ArrowsPointingOutIcon className="inline h-8 w-8" /> Design
      </h3>
      <h2 className="text-4xl md:text-2xl mb-6 text-center">
        Based on Assessment
      </h2>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg">
        We design programs and strategies based on the assessment to produce
        relevant programs, measures and reports. Programs and workshops will be
        designed on the risks, needs, interests, and readiness levels identified
        in the initial Assessment stage.
      </p>
    </section>

    <section className="py-16 max-w-[1400px] mx-auto bg-slate-50">
      <h3 className="text-myorange text-5xl mb-4 text-center">
        <CubeIcon className="inline h-8 w-8" /> Deliver / Implement
      </h3>
      <h2 className="text-4xl md:text-2xl mb-6 text-center">For optimal use</h2>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg">
        Investing in an 'evidence-based' multi-disciplinary programme reaps the
        biggest returns on performance. We work with organizations to develop
        and deliver effective well-being programs. Through our evidence-based
        approach we make sure the strategic direction and the content support
        the needs of the business and the employees themselves.
      </p>
    </section>

    <section className="py-16 max-w-[1400px] mx-auto">
      <h3 className="text-myorange text-5xl mb-4 text-center">
        <FunnelIcon className="inline h-8 w-8" /> Improve
      </h3>
      <h2 className="text-4xl md:text-2xl mb-6 text-center">
        Advancement and Progress
      </h2>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg">
        Our recommendations guide organizations on how to improve their
        programme. Our approach is practical and based on our extensive
        experience of what works and what does not. Good programme design will
        result in good programme effectiveness.
      </p>
    </section>

    <section className="py-16 max-w-[1400px] mx-auto bg-slate-50">
      <h3 className="text-myorange text-5xl mb-4 text-center">
        <BeakerIcon className="inline h-8 w-8" /> Evaluate
      </h3>
      <h2 className="text-4xl md:text-2xl mb-6 text-center">
        Impact and Return on Investment
      </h2>
      <p className="text-lg lg:text-xl mx-auto text-center text-gray-700 max-w-md lg:max-w-lg">
        We provide inside answers about what works in the organizational
        workplace setting with your employees. Evaluation will help determine
        training needs, the budget, program impact, professional development,
        lunch and learns, coaching, which elements need to be identified to
        pinpoint the most important program features etc. Evaluation will also
        identify which elements are important to the organization given its
        intention and values.
      </p>
    </section>

    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/engagement.jpg"
          alt="Decorative image showing the word engagement"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default AboutPage
